import styled from 'styled-components';
import { Modal, theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';

export const RunNewSurveyModal = styled(Modal)`
  .wcl-modal {
    max-width: ${theme.sizes.SIZE_520};
  }

  .wcl-modal__header {
    margin-bottom: ${theme.sizes.SIZE_20};
  }

  .wcl-modal__header__sub {
    margin-top: ${theme.sizes.SIZE_8};
  }

  .calendar-container {
    margin-bottom: ${theme.sizes.SIZE_12};
    max-width: ${theme.sizes.SIZE_304};
  }

  .run-new-suvey-modal {
    &__survey-communities {
      ${flex('row', 'space-between', 'flex-end')}
      margin-top: ${theme.sizes.SIZE_24};
    }

    &__radio-group {
      gap: ${theme.sizes.SIZE_32};
      padding-bottom: ${theme.sizes.SIZE_16};
    }
  }
  svg {
    color: var(--PRIMARY_1_1);
  }
`;
