import styled from 'styled-components';

export const DoughnutChartContainer = styled.div`
  position: relative;

  .doughnut-chart {
    &__text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 18px;
      font-weight: 'bold';
      color: '#333';
      text-align: 'center';
    }
  }
`;
