import { theme } from '@faxi/web-component-library';
import { flex, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const VotesCount = styled.div`
  ${flex('row', undefined, 'center')};
  margin-bottom: ${theme.sizes.SIZE_24};

  svg {
    ${size(theme.sizes.SIZE_24)};
    margin-right: ${theme.sizes.SIZE_12};
    margin-top: ${theme.sizes.SIZE_2};
  }

  .kinto-votes-count {
    &__percentage {
      font-size: ${theme.fontSizes.FONT_20};
      font-weight: 600;
      margin-right: ${theme.sizes.SIZE_4};
      flex: 1;
    }

    &__count {
      border-radius: 18px;
      padding: ${theme.sizes.SIZE_4} ${theme.sizes.SIZE_4} 6px;
      color: var(--BACKGROUND_1_1);
      font-size: ${theme.fontSizes.FONT_12};
      font-weight: 500;
      line-height: 1;
      height: fit-content;
    }
  }

  &.kinto-votes-count {
    &--promoters {
      svg {
        color: var(--ALERT_SUCCESS_1_1);
      }
      .kinto-votes-count__count {
        background-color: var(--ALERT_SUCCESS_1_1);
      }
    }

    &--passives {
      svg {
        color: var(--ALERT_WARNING_1_1);
      }
      .kinto-votes-count__count {
        background-color: var(--ALERT_WARNING_1_1);
      }
    }

    &--detractors {
      svg {
        color: var(--ALERT_ERROR_1_1);
      }
      .kinto-votes-count__count {
        background-color: var(--ALERT_ERROR_1_1);
      }
    }
  }
`;
