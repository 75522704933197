import {
  SelectOption,
  useCallbackRef,
  useUtilities,
} from '@faxi/web-component-library';
import { Form, FormField, FormProps, FormRef } from '@faxi/web-form';
import { SelectField } from 'components';
import { FormActions } from 'Global.styles';
import { useFormButtons, useInfinitePagination } from 'hooks';
import { NetPromoterScoreBasic } from 'models';
import { apiCommunities } from 'modules';
import { apiNetPromoterScore } from 'modules';
import { SearchedCommunity } from 'pages/Users/components/CommuitySearch/CommunitySearch.component';
import { FC, useCallback, useMemo } from 'react';
import { isNonEmptyString } from 'utils';
import specific from 'validation/validators/specific';
import { COMMUNITIES_SEARCH_PER_PAGE } from 'pages/NetPromoterScore/constants';

import * as Styled from './AddMoreCommunitiesModal.styles';

type AddMoreCommunitiesModalProps = {
  netPromoterScore: NetPromoterScoreBasic;

  onClose: () => void;
  onAddMoreCommunities: () => void;
};

const AddMoreCommunitiesModal = (props: AddMoreCommunitiesModalProps) => {
  const { netPromoterScore, onClose, onAddMoreCommunities } = props;

  const [FormButtons] = useFormButtons('Add');

  const [form, formRef] = useCallbackRef<FormRef>();

  const { showSnackBar } = useUtilities();

  const handleSubmit = useCallback(
    async (values: any) => {
      const organisations = values.communities.map(
        (comm: SelectOption) => comm.value
      );

      try {
        const { data } = await apiNetPromoterScore.editNetPromoterScoreSurvey(
          netPromoterScore.id,
          organisations
        );
        if (data) {
          showSnackBar({
            text: 'Changes saved',
            variant: 'success',
            actionButtonText: 'Dismiss',
          });
          onAddMoreCommunities();
          onClose();
        }
      } catch (e) {
        console.error(e);
      }
    },
    [netPromoterScore.id, onAddMoreCommunities, onClose, showSnackBar]
  );

  const modalForm = useCallback<FC<FormProps>>(
    ({ children, className }) => (
      <Form
        className={className}
        onSubmit={handleSubmit}
        ref={formRef}
        children={children}
      />
    ),
    [formRef, handleSubmit]
  );

  const { items: options, handleContainerScroll } = useInfinitePagination<
    SearchedCommunity,
    'organisations'
  >({
    itemsKey: 'organisations',
    searchUrlName: 'community_search',
    perPage: COMMUNITIES_SEARCH_PER_PAGE,
    mappingFunction: (
      communities: Array<SearchedCommunity>
    ): SelectOption<string>[] =>
      communities
        .map(({ id, name }) => ({
          id: `${id}`,
          label: name,
          value: `${id}`,
        }))
        .filter((comm) => isNonEmptyString(comm.label)),
    apiRequest: (page: string, search: string, perPage: string) =>
      apiCommunities.getSearchCommunities({ page, search, per_page: perPage }),
  });

  const validations = useMemo(
    () => ({
      communities: specific.requiredArray('This field is required'),
    }),
    []
  );

  /*
  exclude communities which was alredy included in survey
  **/
  const filteredOptions = useMemo(() => {
    return options.filter(
      (option) =>
        !netPromoterScore.organisations?.some((org) => `${org}` === option.id)
    );
  }, [netPromoterScore.organisations, options]);

  return (
    <Styled.AddMoreCommunitiesModal
      title="Add more communities"
      hasCloseButton
      onClose={onClose}
      childrenWrapper={modalForm}
      footer={
        <FormActions className="edit-form-buttons">
          <FormButtons.Submit disabled={!form?.syncFormValid} />
          <FormButtons.Cancel onClick={() => onClose()} />
        </FormActions>
      }
    >
      <FormField
        name="communities"
        multiple
        autoComplete="off"
        component={SelectField}
        options={filteredOptions}
        placeholder={'Select communities'}
        searchable
        renderAsPortal
        hasClearAction
        clearTitle="Clear"
        validate={validations.communities}
        required
        requiredLabel="Required"
        onContainerScroll={handleContainerScroll}
        className="run-new-suvey-modal__communities"
      />
    </Styled.AddMoreCommunitiesModal>
  );
};

export default AddMoreCommunitiesModal;
