import dayjs, { Dayjs } from 'dayjs';

export const getDatesBetween = (startDate: Dayjs, endDate: Dayjs) => {
  const dates: Dayjs[] = [];
  let currentDate = dayjs(startDate);

  while (dayjs(endDate).isAfter(currentDate)) {
    dates.push(currentDate);
    currentDate = dayjs(currentDate).add(1, 'day');
  }

  dates.push(endDate);
  return dates;
};

export const validateEndDate =
  (message: string, disabled: Dayjs[]) => (end_date: Dayjs, fields: any) => {
    const start_date = fields['start_date'].value as Dayjs;
    const dates_between = getDatesBetween(start_date, end_date);

    if (dates_between.find((d) => disabled.find((dd) => dd.isSame(d)))) {
      return message;
    }

    return undefined;
  };
