import classNames from 'classnames';
import { Icon } from 'components';

import * as Styled from './VotesCount.styles';

const MAP_VOTES_TYPE_TO_ICON = {
  detractors: 'face-frown-slight',
  passives: 'face-meh',
  promoters: 'face-smile',
};

type VotesCountType = {
  type: 'detractors' | 'passives' | 'promoters';
  percentage: number;
  count: number;
};

const VotesCount = (props: VotesCountType) => {
  const { type, percentage, count } = props;

  return (
    <Styled.VotesCount
      className={classNames([
        `kinto-votes-count`,
        `kinto-votes-count--${type}`,
      ])}
    >
      <Icon name={MAP_VOTES_TYPE_TO_ICON[type] as any} />
      <span className="kinto-votes-count__percentage">{percentage.toFixed(0)}%</span>
      <span className="kinto-votes-count__count">{count} users</span>
    </Styled.VotesCount>
  );
};

export default VotesCount;
