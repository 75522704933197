import { useCallback, useContext, useMemo, useRef, useState, FC } from 'react';
import {
  Input,
  Modal,
  RadioGroup,
  SortOption,
  Table,
  Switch,
  useCallbackRef,
  useEffectSkipFirst,
  Heading,
  useQueryParams,
  useUtilities,
  StatusElement,
  StatusElementStatus,
  ModalRef,
  Button,
  getColor,
  useModalUtilities,
} from '@faxi/web-component-library';
import { AxiosError } from 'axios';
import { Form, FormField, FormProps, FormRef } from '@faxi/web-form';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import classNames from 'classnames';
import {
  CalendarField,
  SelectField,
  Icon,
  TablePageLayout,
  NoPermissionsPlaceholder,
} from 'components';
import { useCallbackAsync, useFormButtons, useTablePagination } from 'hooks';
import { appUri, COLUMNS_SETTINGS_LABELS, dateFormat } from 'config';
import { apiCommunities } from 'modules';
import { Community } from 'models';
import {
  dateInUTCFormat,
  snackBarErrorMessage,
  snackBarSuccessMessage,
} from 'utils';
import { useColumnSettings } from 'hooks';
import { maxLength, numbersOnly } from 'validation/general';
import { allowOnlyFutureDate } from 'validation/validations';
import AuthContext from 'store/AuthProvider/Auth.context';

import { FormActions } from 'Global.styles';
import PremiumFeaturesModal from './components/PremiumFeaturesModal';
import {
  CommunityStatus,
  COMMUNITY_STATUS,
  PremiumFeature,
  PremiumFeaturesNames,
  VerificationType,
  CreateCommunityData,
} from 'models';
import CommunitySettingsModal from './components/CommunitySettingsModal';
import { INameExtended } from 'components/Icon';
import { InputValidator } from '@faxi/web-component-library/build/components/_molecules/Input/Input.component';
import { storageService } from 'services';
import { STORAGE_KEYS } from 'services/storageService';
import CreateCommunityModal from './components/CreateCommunityModal';
import RewardsModal from './components/RewardsModal';

enum MAP_POCS {
  N = 'None',
  A = 'Accepted',
  S = 'Strong',
  W = 'Walking',
  C = 'Cycling',
  M = 'Manual',
  B = 'Bluetooth',
  undefined = '',
}

enum MAP_LEVEL {
  'Free' = 'Free',
  'P001' = 'Business',
  'P002' = 'Business+',
  'P003' = 'Live',
  undefined = '',
}

const paramsPlaceholders: Record<string, string> = {
  id: 'ID',
  name: 'Name',
  postcode: 'Postcode',
};

export const SEARCH_PARAMS = {
  organisation_id: 'id',
  organisation_name: 'name',
  organisation_postcode: 'postcode',
} as const;

export const PREMIUM_FEATURES_ICONS = {
  carpooling: 'car',
  cycling: 'person-biking',
  walking: 'person-walking',
  people_page: 'user-group',
  journeys_page: 'route',
  planner_page: 'calendar-clock',
  messages_page: 'comment',
  gamification_feature: 'gamepad-modern',
  parking_barrier: 'road-barrier',
  event_feature: 'calendar-day',
  use_fullname: 'id-card',
  booking_price: '',
  booking_polyline: '',
  live_map_preview: 'location-crosshairs',
  survey: 'clipboard-list-check',
  emission_optimisation: '',
  booking_client_admin: 'calendar-clock',
  map_client_admin: 'map-location-dot',
} as Record<PremiumFeaturesNames, string>;

export const STATUS_MAP = {
  [COMMUNITY_STATUS.ACTIVE]: 'active',
  [COMMUNITY_STATUS.DEACTIVATED]: 'canceled',
  [COMMUNITY_STATUS.HIDDEN]: 'pending',
  [COMMUNITY_STATUS.DELETED]: 'rejected',
} as Record<CommunityStatus, StatusElementStatus>;

export const STATUS_TRANSLATIONS = {
  [COMMUNITY_STATUS.ACTIVE]: 'Active',
  [COMMUNITY_STATUS.DEACTIVATED]: 'Deactivated',
  [COMMUNITY_STATUS.HIDDEN]: 'Hidden',
  [COMMUNITY_STATUS.DELETED]: 'Deleted',
} as Record<CommunityStatus, string>;

const premium_features_shown_in_table = ['carpooling', 'walking', 'cycling'];

export type CommunitiesSearchParams =
  (typeof SEARCH_PARAMS)[keyof typeof SEARCH_PARAMS];

const Communities: FC = (): JSX.Element => {
  const { admin } = useContext(AuthContext);

  const { showOverlay, hideOverlay, prompts } = useUtilities();

  const [showLicenceModal, setShowLicenceModal] = useState(false);
  const [showEditJsonmodal, setShowEditJSONModal] = useState(false);
  const [showCreateCommunityModal, setShowCreateCommunityModal] =
    useState(false);
  const [triggerApiRequest, setTriggerApiRequest] = useState(false);
  const [premiumFeatureModal, setPremiumFeatureModal] =
    useState<boolean>(false);

  const {
    open: showRewardsModal,
    openModal: openRewardModal,
    closeModal: closeRewardModal,
  } = useModalUtilities();

  const {
    columnBtnRef,
    columnSettingsOpen,
    closeColumnSettings,
    ColumnSettingsButton,
  } = useColumnSettings();

  const {
    params: { searchParam = SEARCH_PARAMS.organisation_id, hide_test },
    setQueryParam,
    setMultipleQueryParams,
    removeQueryParam,
  } = useQueryParams<{
    page: string;
    searchParam: CommunitiesSearchParams;
    hide_test?: 'true';
  }>();

  const [hideTestCommunities, setHideTestCommunities] = useState(!!hide_test);

  const [FormButtons] = useFormButtons('Save changes');
  const [licenceForm, licenceFormRef] = useCallbackRef<FormRef>();

  const history = useHistory();

  const selectedLicence = useRef({
    premium: '',
    eofp: '',
    pocs: '',
  });

  const selectedCommunity = useRef<{
    id: number;
    premium_features: PremiumFeature[];
  }>();

  const communityToEdit = useRef<CreateCommunityData>();

  const licenceBtnRef = useRef<HTMLButtonElement>();
  const settingsBtnRef = useRef<HTMLButtonElement>();
  const editButtonRef = useRef<HTMLButtonElement>();
  const premiumFeatureBtnRef = useRef<HTMLButtonElement>();
  const deactivateBtnRef = useRef<HTMLButtonElement>();
  const licenceModalRef = useRef<ModalRef>(null);

  const modeOfTransportation = useCallback((data: Array<PremiumFeature>) => {
    if (!data) return null;

    return (
      <div className="transport-mode">
        {Object.values(data).map((value) => (
          <Icon
            key={value.id}
            name={PREMIUM_FEATURES_ICONS[value.name] as INameExtended}
          />
        ))}
      </div>
    );
  }, []);

  const mapCommunitiesData = useCallback(
    (communities: Array<Community>) =>
      communities.map(
        ({
          id,
          name,
          pocs,
          premium,
          end_of_premium,
          postcode,
          registered_users,
          expected_users,
          carparks_count,
          premium_features,
          verification_type,
          status,
          created_at,
        }) =>
          ({
            id,
            name,
            pocs: MAP_POCS[`${pocs}`] || '-',
            premium: premium ? MAP_LEVEL[`${premium}`] : '-',
            end_of_premium: dateInUTCFormat(end_of_premium, dateFormat),
            postcode: postcode || '-',
            registered_users: registered_users || '-',
            expected_users: expected_users || '-',
            carparks_count,
            created_at: dayjs(created_at).format(dateFormat),
            premium_features,
            verification_type,
            premium_features_element: modeOfTransportation(
              premium_features.filter(({ name }) =>
                premium_features_shown_in_table.includes(name)
              )
            ),
            status_value: status,
            status: (
              <StatusElement status={STATUS_MAP[status as CommunityStatus]}>
                {STATUS_TRANSLATIONS[status as CommunityStatus]}
              </StatusElement>
            ),
          } as unknown as Community)
      ),
    [modeOfTransportation]
  );

  const {
    data,
    count,
    requestError,
    totalCount,
    totalPages,
    currentPage,
    search,
    activeColumnSort,
    setCount,
    setCurrentPage,
    setSearch,
    setActiveColumnSort,
    setData: setCommunities,
  } = useTablePagination<Community, 'organisations'>({
    deps: [triggerApiRequest, hideTestCommunities],
    itemsKey: 'organisations',
    spinnerParentClass: '.kinto-page',
    initialParams: {
      sortBy: 'id',
      sortDirection: 'desc',
      searchParam,
      page: 1,
    },
    mappingFunction: (values: Array<Community>) => mapCommunitiesData(values),
    apiRequest: ({
      per_page,
      currentPage,
      searchString,
      sort_by,
      sort_direction,
    }) =>
      apiCommunities.getAllCommunities({
        page: currentPage,
        per_page,
        orglist: 1,
        search_key: searchParam || undefined,
        search_value: searchString || undefined,
        sort_by,
        sort_direction,
        hide_test: hideTestCommunities,
      }),
  });

  const canEdit = useMemo(
    () =>
      !!admin?.permissions.find((p) => p.name === 'community_management_edit'),
    [admin]
  );

  const handleOnColumnSort = useCallback(
    (sortOptions: SortOption<Community>) => {
      setActiveColumnSort(sortOptions);
      setCurrentPage(1);
    },
    [setActiveColumnSort, setCurrentPage]
  );

  const handleOnUpdateStatus = useCallback(
    async (oid: number, name: string) => {
      if (
        await prompts.delete({
          title: `Do you really want to disable ${name} community?`,
          btnIcon: 'ban',
          iconPosition: 'right',
          submitBtnText: 'Disable',
          cancelBtnText: 'Cancel',
        })
      ) {
        try {
          showOverlay('body', 'fixed');

          await apiCommunities.updateCommunityStatus(
            `${oid}`,
            COMMUNITY_STATUS.DEACTIVATED
          );

          setCommunities((oldCommunities) =>
            oldCommunities.map((comm) =>
              comm.id === oid
                ? {
                    ...comm,
                    status: (
                      <StatusElement
                        status={STATUS_MAP[COMMUNITY_STATUS.DEACTIVATED]}
                      >
                        {STATUS_TRANSLATIONS[COMMUNITY_STATUS.DEACTIVATED]}
                      </StatusElement>
                    ),
                    status_value: COMMUNITY_STATUS.DEACTIVATED,
                  }
                : comm
            )
          );

          snackBarSuccessMessage(`${name} successfully disabled`);
        } catch (e) {
          console.error(e);
          snackBarErrorMessage(e as AxiosError);
        } finally {
          hideOverlay('body');
        }
      }
      deactivateBtnRef.current?.focus();
    },
    [hideOverlay, prompts, setCommunities, showOverlay]
  );

  const translationKeys = useMemo(
    () =>
      ({
        id: 'Community ID',
        name: 'Community name',
        pocs: 'PoCS',
        premium: 'Level',
        end_of_premium: 'End of premium date',
        postcode: 'Postcode',
        registered_users: 'Registered users',
        expected_users: 'Expected users',
        carparks_count: 'Car parks',
        created_at: 'Created',
        verification_type: 'Verification',
        premium_features_element: 'Mode of transport',
        status: 'Status',
      } as Record<Partial<keyof Community>, string>),
    []
  );

  const tableActions = useMemo(
    () =>
      ({
        id,
        name,
        premium,
        end_of_premium,
        premium_features,
        pocs,
        status_value,
      }: Community) =>
        (
          <TablePageLayout.TableActions
            actions={[
              {
                name: 'Journeys',
                icon: 'route',
                onClick: () => {
                  storageService.setItem(STORAGE_KEYS.SELECTED_COMMUNITY, name);
                  history.push(appUri.COMMUNITIES_JOURNEYS(`${id}`));
                },
              },
              {
                name: 'Admins',
                icon: 'user-gear',
                onClick: () => {
                  storageService.setItem(STORAGE_KEYS.SELECTED_COMMUNITY, name);
                  history.push({
                    pathname: appUri.COMMUNITIES_ADMINS(`${id}`),
                    search: `?community=${name}`,
                  });
                },
              },
              {
                name: 'Car parks',
                icon: 'cars',
                onClick: () => {
                  storageService.setItem(STORAGE_KEYS.SELECTED_COMMUNITY, name);
                  history.push(appUri.COMMUNITIES_CARPARK(`${id}`));
                },
              },
              {
                icon: 'users',
                name: 'Users',
                onClick: () => {
                  history.push(
                    `/users?community_id=${id}&community_name=${name}`
                  );
                },
              },
              ...(canEdit
                ? (
                    [
                      {
                        icon: 'gift',
                        name: 'Rewards',
                        onClick: () => {
                          selectedCommunity.current = {
                            id,
                            premium_features,
                          };
                          openRewardModal();
                        },
                      },
                      {
                        name: 'Licence',
                        icon: 'file-certificate',
                        onClick: (btnElement: HTMLButtonElement) => {
                          selectedCommunity.current = {
                            id,
                            premium_features,
                          };
                          selectedLicence.current = {
                            premium: premium || '',
                            eofp: end_of_premium || '',
                            pocs: pocs || '',
                          };
                          setShowLicenceModal(true);
                          licenceBtnRef.current = btnElement;
                        },
                      },
                      {
                        name: 'Settings',
                        icon: 'gear',
                        onClick: async (btnElement: HTMLButtonElement) => {
                          setShowEditJSONModal(true);
                          settingsBtnRef.current = btnElement;
                          selectedCommunity.current = {
                            id,
                            premium_features,
                          };
                        },
                      },
                      {
                        name: 'Premium features',
                        icon: 'stars',
                        onClick: (btnElement: HTMLButtonElement) => {
                          setPremiumFeatureModal(true);
                          premiumFeatureBtnRef.current = btnElement;

                          selectedCommunity.current = {
                            id,
                            premium_features,
                          };
                        },
                      },
                      {
                        icon: 'pen',
                        name: 'Edit',
                        onClick: async (btnElement: HTMLButtonElement) => {
                          editButtonRef.current = btnElement;

                          showOverlay('.kinto-page');

                          const { data } = await apiCommunities.getCommunity(
                            id
                          );

                          hideOverlay('.kinto-page');

                          communityToEdit.current = data;

                          setShowCreateCommunityModal(true);
                        },
                      },
                    ] as Array<any>
                  ).concat(
                    status_value !== COMMUNITY_STATUS.DEACTIVATED
                      ? [
                          {
                            name: 'Disable',
                            variant: 'delete-ghost',
                            icon: 'ban',
                            onClick: (element: HTMLButtonElement) => {
                              deactivateBtnRef.current = element;
                              handleOnUpdateStatus(id, name);
                            },
                          },
                        ]
                      : []
                  )
                : []),
            ]}
          />
        ),
    [
      canEdit,
      history,
      openRewardModal,
      showOverlay,
      hideOverlay,
      handleOnUpdateStatus,
    ]
  );

  const [handleChangeLicence, loadingLicence] = useCallbackAsync({
    showSpinner: false,
    callback: async (formData: {
      premium: string;
      pocs: string;
      eofp: string;
    }) => {
      try {
        const {
          data: { status },
        } = await apiCommunities.updateCommunityLicence(
          Number(selectedCommunity.current?.id),
          formData.premium,
          formData.pocs,
          formData.eofp === '-'
            ? undefined
            : dayjs(formData.eofp, dateFormat).format('YYYY/MM/DD')
        );

        if (status === 'Success') {
          setTriggerApiRequest(!triggerApiRequest);
          setShowLicenceModal(false);
        }
      } catch (e) {
        console.error(e);
      }
    },
  });

  const modalForm = useCallback<FC<FormProps>>(
    ({ children, className }) => (
      <Form
        className={className}
        onSubmit={handleChangeLicence}
        initialData={selectedLicence.current}
        ref={licenceFormRef}
        children={children}
      />
    ),
    [handleChangeLicence, licenceFormRef]
  );

  const handleOnPremiumFeaturesChanged = useCallback(
    (cid: number, premium_features: PremiumFeature[]) => {
      setCommunities((oldCommunities) =>
        oldCommunities.map((community) => {
          if (community.id !== cid) return community;

          return {
            ...community,
            premium_features,
            premium_features_element: modeOfTransportation(
              premium_features.filter(({ name }) =>
                premium_features_shown_in_table.includes(name)
              )
            ),
          };
        })
      );
    },
    [modeOfTransportation, setCommunities]
  );

  const handleOnSettingsChanged = useCallback(
    (cid: number, verification_type: VerificationType) => {
      setCommunities((oldCommunities) =>
        oldCommunities.map((community) => {
          if (community.id !== cid) return community;
          return {
            ...community,
            verification_type,
          };
        })
      );
    },
    [setCommunities]
  );

  const inputValidations = useMemo<Record<string, InputValidator<string>[]>>(
    () => ({
      id: [
        numbersOnly('Only numbers are allowed'),
        maxLength('Maximum number of characters is 10', 10),
      ],
      name: [maxLength('Maximum number of characters is 60', 60)],
      postcode: [maxLength('Maximum number of characters is 30', 30)],
    }),
    []
  );

  useEffectSkipFirst(() => {
    setHideTestCommunities(!!hide_test);
    setCurrentPage(1);
  }, [hide_test]);

  return requestError ? (
    <NoPermissionsPlaceholder />
  ) : (
    <TablePageLayout.PageLayoutContainer className="kinto-page">
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__header"
      >
        Communities
      </Heading>

      <RadioGroup
        className="kinto-page__search-param"
        name="searchParam"
        options={[
          { label: 'Community ID', value: 'id' },
          { label: 'Community name', value: 'name' },
          { label: 'Community postcode', value: 'postcode' },
        ]}
        orientation="row"
        selected={searchParam}
        onChange={(value) => {
          setSearch('');

          setMultipleQueryParams({
            page: 1,
            searchParam: value as CommunitiesSearchParams,
          });
        }}
      />

      <div className="kinto-page__actions">
        <Input
          placeholder={paramsPlaceholders[searchParam]}
          validators={inputValidations[searchParam]}
          value={search}
          prefixIcon={<Icon name="search" />}
          {...(search && {
            suffixIcon: (
              <Button
                variant="ghost"
                aria-label="Delete input search value"
                onClick={() => setSearch('')}
                icon={<Icon name="xmark" />}
              />
            ),
          })}
          onChange={(value, error) => {
            setSearch(value, error);
            setQueryParam('page', 1);
          }}
        />

        {canEdit && (
          <>
            <Button
              variant="outline"
              icon={<Icon name="plus" />}
              onClick={() => {
                history.push(appUri.CREATE_COMMUNITIY);
              }}
            >
              Create a community
            </Button>

            <Button
              variant="outline"
              icon={<Icon name="star" />}
              onClick={() => {
                history.push(appUri.NET_PROMOTER_SCORE);
              }}
            >
              Net Promoter Score
            </Button>
          </>
        )}

        <Switch
          className="kinto-page__actions__right-side__toggle"
          label="Hide test communities"
          value={hide_test === 'true'}
          onChange={() =>
            !hide_test
              ? setQueryParam('hide_test', 'true')
              : removeQueryParam('hide_test')
          }
        />
        {data.length !== 0 && <ColumnSettingsButton />}
      </div>

      {data.length === 0 ? (
        <div className="kinto-page__empty-state">
          Sorry, there are no results that match your search.
        </div>
      ) : (
        <Table<Community>
          expandable
          cacheColumns
          tableData={data}
          tableId="communities-table"
          translationKeys={translationKeys}
          tableActions={tableActions}
          columnSettingsOpen={columnSettingsOpen}
          columnsBtnElement={columnBtnRef.current!}
          columnsModalLabels={COLUMNS_SETTINGS_LABELS}
          excludeColumns={['bonus', 'crm', 'premium_features', 'status_value']}
          excludeSortColumns={['premium_features_element', 'verification_type']}
          initialSort={activeColumnSort}
          className="kinto-communities__table"
          paginationData={{
            currentPage,
            limit: count,
            totalCount,
            totalPages,
          }}
          goToPageInputProps={{ placeholder: 'Go to page' }}
          onPageChange={(value) => {
            setCurrentPage(value);
            setQueryParam('page', value);
          }}
          onLimitChange={(data) => {
            setCurrentPage(1);
            setCount(+data.value);
          }}
          onColumnsModalClose={closeColumnSettings}
          onColumnSortClicked={handleOnColumnSort}
          breakAtMaxWidth={1500}
        />
      )}

      {showLicenceModal && (
        <Modal
          title="Licence"
          loading={loadingLicence}
          childrenWrapper={modalForm}
          ref={licenceModalRef}
          onClose={() => {
            setShowLicenceModal(false);
            licenceBtnRef.current?.focus();
          }}
          footer={
            <FormActions className="kinto-modal__actions">
              <FormButtons.Submit disabled={!licenceForm?.isFormChanged()} />
              <FormButtons.Cancel
                onClick={() => licenceModalRef.current?.close()}
              />
            </FormActions>
          }
        >
          <div
            className={classNames(
              'kinto-modal__grid-fields',
              'kinto-modal__grid-fields--2-rows'
            )}
          >
            <FormField
              name="premium"
              className="kinto-modal__grid-fields__premium"
              placeholder="Type"
              component={SelectField}
              renderAsPortal
              options={[
                { label: 'Free', value: 'Free' },
                { label: 'Business', value: 'Business' },
                { label: 'Business+', value: 'Business+' },
                { label: 'Live', value: 'Live' },
              ]}
            />

            <FormField
              name="eofp"
              className="kinto-modal__grid-fields__eofp"
              component={CalendarField}
              renderAsPortal
              placeholder="End of premium"
              leftLimitDate={dayjs().add(1, 'day')}
              rightLimitDate={dayjs().add(20, 'years')}
              validate={allowOnlyFutureDate(
                'End of premium date must be in future.'
              )}
            />

            <FormField
              name="pocs"
              placeholder="Proof of car share"
              component={SelectField}
              renderAsPortal
              className="kinto-modal__grid-fields__pocs"
              options={[
                { label: 'Strong', value: 'Strong' },
                { label: 'None', value: 'None' },
              ]}
            />
          </div>
        </Modal>
      )}

      {premiumFeatureModal && (
        <PremiumFeaturesModal
          community={selectedCommunity.current}
          onSubmit={handleOnPremiumFeaturesChanged}
          onClose={() => {
            setPremiumFeatureModal(false);
            premiumFeatureBtnRef.current?.focus();
          }}
        />
      )}

      {showEditJsonmodal && (
        <CommunitySettingsModal
          communityId={selectedCommunity.current?.id}
          onSubmit={handleOnSettingsChanged}
          onClose={() => {
            setShowEditJSONModal(false);
            settingsBtnRef.current?.focus();
          }}
        />
      )}

      {showCreateCommunityModal && (
        <CreateCommunityModal
          community={communityToEdit.current}
          onSubmit={() => {
            setTriggerApiRequest(!triggerApiRequest);
          }}
          onClose={() => {
            setShowCreateCommunityModal(false);

            if (communityToEdit.current) {
              editButtonRef.current?.focus();
            }
            communityToEdit.current = undefined;
          }}
        />
      )}
      {showRewardsModal && selectedCommunity.current?.id && (
        <RewardsModal
          selectedCommunityId={selectedCommunity.current?.id}
          onClose={closeRewardModal}
        />
      )}
    </TablePageLayout.PageLayoutContainer>
  );
};

export default Communities;
