import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
// remove this package from project when wcl is ready
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';

import * as Styled from './DoughnutChart.styles';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

type ChartDataType = {
  value: number;
  color: string;
  label?: string;
};

export type DoughnutChartProps = {
  chartData: ChartDataType[];
  centerText: string | JSX.Element;
  className?: string;
};

const DoughnutChart = (props: DoughnutChartProps) => {
  const { chartData, centerText, className } = props;

  const data = useMemo(() => {
    return {
      labels: chartData?.map((data) => data.label),
      datasets: [
        {
          data:
            chartData?.length > 1
              ? chartData?.map((data) => data.value)
              : [100],
          backgroundColor:
            chartData?.length !== 0
              ? chartData?.map((data) => data.color)
              : 'gray',
          borderColor: ['white'],
          borderWidth: chartData?.length > 1 ? 4 : 0,
        },
      ],
    };
  }, [chartData]);

  const options = useMemo(() => {
    return {
      cutout: '65%',
      plugins: {
        datalabels: {
          color: '#fff',
          font: {
            weight: '400' as const,
            size: 14,
          },
          formatter: (value: number, context: any) => {
            // Calculate percentage
            const total = context.dataset.data.reduce(
              (acc: number, val: number) => acc + val,
              0
            );
            const percentage = ((value / total) * 100).toFixed(0);

            if (percentage === '100' && chartData.length === 0) {
              return `0%`;
            }
            if (percentage) {
              return `${percentage}%`;
            }
          },
        },
        tooltip: {
          enabled: false,
          displayColors: false,
        },
        legend: {
          display: false,
        },
      },
    };
  }, [chartData]);

  return (
    <Styled.DoughnutChartContainer className={className}>
      <div className="doughnut-chart__text">{centerText}</div>
      <Doughnut data={data} options={options as any} />
    </Styled.DoughnutChartContainer>
  );
};

export default DoughnutChart;
