import { theme } from '@faxi/web-component-library';
import { flex, phablet, pxToRem, size } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const NetPromoteScore = styled.div`
  padding: ${theme.sizes.SIZE_24} ${theme.sizes.SIZE_72};
  background: var(--BACKGROUND_2_1);
  overflow: auto;

  h1 {
    color: var(--PRIMARY_1_1);
    margin-top: ${theme.sizes.SIZE_16};
  }

  .net-promote-score {
    &__desc {
      color: var(--SHADE_1_2);
      font-size: ${theme.fontSizes.FONT_14};
      margin: 0;
      margin-bottom: ${theme.sizes.SIZE_16};
    }

    &__run-survey-btn {
      margin-bottom: ${theme.sizes.SIZE_32};
    }

    &__tabs {
      margin-bottom: ${theme.sizes.SIZE_16};
    }

    &__questions {
      background-color: var(--BACKGROUND_1_1);
      border-radius: ${theme.sizes.SIZE_8};
      padding: ${theme.sizes.SIZE_16};
      max-width: ${theme.sizes.SIZE_728};
    }

    &__info-lbl {
      font-size: ${theme.fontSizes.FONT_14};
      color: var(--SHADE_1_1);
    }

    &__info {
      color: var(--SHADE_1_1);
      font-weight: 600;
      margin-bottom: ${theme.sizes.SIZE_16};
      margin-top: ${theme.sizes.SIZE_4};
    }

    &__responses {
      &__empty-state {
        ${flex('column', undefined, 'flex-start')};
        margin-top: ${theme.sizes.SIZE_48};

        svg {
          ${size(theme.sizes.SIZE_48)};
          color: var(--PRIMARY_1_1);
        }

        h2 {
          font-size: ${theme.fontSizes.FONT_24};
          color: var(--SHADE_1_1);
        }
      }

      &__list {
        display: grid;
        grid-gap: ${theme.sizes.SIZE_20};
        grid-template-columns: repeat(
          auto-fit,
          ${pxToRem(theme.sizes.SIZE_256)}
        );

        ${phablet(css`
          grid-template-columns: 1fr;
          grid-gap: ${theme.sizes.SIZE_8};
        `)}
      }
    }
  }

  .wcl-tabs__option--active {
    background-color: white;
  }

  ul {
    padding-left: ${theme.sizes.SIZE_32};
    margin: 0;
  }
  p {
    margin: 0;
  }
`;
