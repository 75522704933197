import httpClient from '../httpClient';

type RunNetPormoterScoreArgs = {
  start_date: string;
  end_date: string;
  organisations: number[] | 'all';
};
const runNetPromoterScoreSurvey = ({
  start_date,
  end_date,
  organisations,
}: RunNetPormoterScoreArgs) => {
  const formData = new FormData();
  formData.append('start_date', start_date);
  formData.append('end_date', end_date);
  formData.append(
    'organisations',
    organisations === 'all' ? organisations : JSON.stringify(organisations)
  );

  return httpClient.post(`platform-surveys/run`, formData);
};

const stopNetPromoterScoreSurvey = (surveyId: number) =>
  httpClient.put(`platform-surveys/${surveyId}/stop`);

const getAllNetPromoterScoreSurveys = () =>
  httpClient.get(`platform-surveys/all `);

const getPromoterScoreSurveyById = (id: string, organisation_id?: string) =>
  httpClient.get(`platform-surveys/${id}/get`, { params: { organisation_id } });

const editNetPromoterScoreSurvey = (
  surveyId: number,
  organisations: number[]
) => httpClient.put(`platform-surveys/${surveyId}/edit`, { organisations });

export default {
  runNetPromoterScoreSurvey,
  stopNetPromoterScoreSurvey,
  getAllNetPromoterScoreSurveys,
  getPromoterScoreSurveyById,
  editNetPromoterScoreSurvey,
};
