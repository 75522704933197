import { FC, useCallback, useMemo } from 'react';
import { useInfinitePagination } from 'hooks';

import {
  Form,
  FormField,
  FormProps,
  FormRef,
  useFormRefValues,
  validators,
} from '@faxi/web-form';
import { CalendarField, RadioGroupField, SelectField } from 'components';
import dayjs, { Dayjs } from 'dayjs';
import {
  Button,
  SelectOption,
  useCallbackRef,
  useUtilities,
} from '@faxi/web-component-library';
import { SearchedCommunity } from 'pages/Users/components/CommuitySearch/CommunitySearch.component';
import { apiCommunities, apiNetPromoterScore } from 'modules';
import { isNonEmptyString } from 'utils';
import specific from 'validation/validators/specific';
import { dateFormat } from 'config';
import {
  COMMUNITIES_SEARCH_PER_PAGE,
  forCommunitiesRadioOptions,
  runSurveyFormInitialData,
} from 'pages/NetPromoterScore/constants';

import * as Styled from './RunNewSurveyModal.styles';
import { validateEndDate } from 'pages/NetPromoterScore/utils';

type RunNewSurveyModalProps = {
  disabledDates?: Dayjs[];
  onClose: () => void;
  onRunSurvey: () => void;
};

const RunNewSurveyModal = (props: RunNewSurveyModalProps) => {
  const { disabledDates, onClose, onRunSurvey } = props;

  const [form, formRef] = useCallbackRef<FormRef>();

  const { showSnackBar } = useUtilities();

  const handleSubmit = useCallback(
    async (values: any) => {
      const { start_date, end_date, forCommunities, communities } = values;

      try {
        const { data } = await apiNetPromoterScore.runNetPromoterScoreSurvey({
          start_date: dayjs(start_date, dateFormat).format('YYYY-MM-DD'),
          end_date: dayjs(end_date, dateFormat).format('YYYY-MM-DD'),
          organisations:
            forCommunities === 'all'
              ? 'all'
              : communities.map((comm: SelectOption) => comm.value),
        });

        if (data) {
          showSnackBar({
            text: 'Survey created',
            variant: 'success',
          });

          onClose();
          onRunSurvey();
        }
      } catch (e) {
        console.error(e);
      }
    },
    [onClose, onRunSurvey, showSnackBar]
  );

  const formValues = useFormRefValues(
    form,
    'forCommunities',
    'end_date',
    'start_date'
  );

  const modalForm = useCallback<FC<FormProps>>(
    ({ children, className }) => (
      <Form
        className={className}
        onSubmit={handleSubmit}
        ref={formRef}
        children={children}
        initialData={runSurveyFormInitialData}
      />
    ),
    [formRef, handleSubmit]
  );

  const { items: options, handleContainerScroll } = useInfinitePagination<
    SearchedCommunity,
    'organisations'
  >({
    itemsKey: 'organisations',
    searchUrlName: 'community_search',
    perPage: COMMUNITIES_SEARCH_PER_PAGE,
    mappingFunction: (
      communities: Array<SearchedCommunity>
    ): SelectOption<string>[] =>
      communities
        .map(({ id, name }) => ({
          id: `${id}`,
          label: name,
          value: `${id}`,
        }))
        .filter((comm) => isNonEmptyString(comm.label)),
    apiRequest: (page: string, search: string, perPage: string) =>
      apiCommunities.getSearchCommunities({ page, search, per_page: perPage }),
  });

  const validations = useMemo(
    () => ({
      start_date: [validators.general.required('This field is required')],
      end_date: [
        validators.general.required('This field is required'),
        validateEndDate(
          'In this range there is already an active net promoter score survey. Choose another range. ',
          disabledDates as Dayjs[]
        ),
      ],
      communities: specific.requiredArray('This field is required'),
    }),
    [disabledDates]
  );

  return (
    <Styled.RunNewSurveyModal
      title="Run New Survey"
      hasCloseButton
      subtitle="Mobile app users and admins on the admin app will get a one question survey"
      onClose={onClose}
      childrenWrapper={modalForm}
      footer={
        <Button type="submit" disabled={!form?.syncFormValid}>
          Run survey
        </Button>
      }
    >
      <FormField
        name="start_date"
        required
        requiredLabel="Required"
        component={CalendarField}
        renderAsPortal
        placeholder="Start date"
        leftLimitDate={dayjs()}
        rightLimitDate={
          formValues?.end_date
            ? dayjs(formValues?.end_date, 'DD.MM.YYYY')
            : dayjs().add(2, 'years')
        }
        validate={validations.start_date}
        disabledDates={disabledDates}
      />

      <FormField
        name="end_date"
        required
        requiredLabel="Required"
        component={CalendarField}
        renderAsPortal
        placeholder="End date"
        leftLimitDate={
          formValues?.start_date
            ? dayjs(formValues?.start_date, 'DD.MM.YYYY')
            : dayjs()
        }
        rightLimitDate={dayjs().add(2, 'years')}
        validate={validations.end_date}
        disabledDates={disabledDates}
      />

      <div className="run-new-suvey-modal__survey-communities">
        <FormField
          name="forCommunities"
          component={RadioGroupField}
          orientation="column"
          options={forCommunitiesRadioOptions}
          className="run-new-suvey-modal__radio-group"
        />

        {formValues?.forCommunities === 'per_community' && (
          <FormField
            name="communities"
            multiple
            autoComplete="off"
            component={SelectField}
            options={options}
            placeholder={'Select communities'}
            searchable
            renderAsPortal
            hasClearAction
            clearTitle="Clear"
            validate={validations.communities}
            required
            requiredLabel="Required"
            onContainerScroll={handleContainerScroll}
            className="run-new-suvey-modal__communities"
          />
        )}
      </div>
    </Styled.RunNewSurveyModal>
  );
};

export default RunNewSurveyModal;
