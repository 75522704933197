import { useHistory } from 'react-router';
import {
  Button,
  Icon,
  StatusElement,
  StatusElementStatus,
} from '@faxi/web-component-library';
import { appUri, dateFormat } from 'config';
import dayjs from 'dayjs';
import { NetPromoterScoreBasic } from 'models';
import {
  MAP_NET_PROMOTER_SCORE_STATUS,
  MAP_NET_PROMOTER_SCORE_STATUS_FOR_STATUS_ELEMENT,
  MAP_NET_PROMOTER_SCORE_TYPE,
} from 'pages/NetPromoterScore/constants';

import * as Styled from './NetPromoterScoreCard.styles';

type NetPromoterScoreCardProps = {
  netPromoterScore: NetPromoterScoreBasic;
  onEdit?: () => void;
};

const NetPromoterScoreCard = (props: NetPromoterScoreCardProps) => {
  const {
    netPromoterScore: {
      id,
      start_time,
      end_time,
      participants_total,
      status,
      all = 'selected',
      participants_completed,
    },
    onEdit,
  } = props;

  const history = useHistory();

  return (
    <Styled.NetPromoterScoreCard
      onClick={() => {
        history.push(appUri.NET_PROMOTER_SCORE_SURVEY(`${id}`));
      }}
    >
      <h2>{MAP_NET_PROMOTER_SCORE_TYPE[all ? 'all' : 'selected']}</h2>

      <div className="net-promoter-score-card__basic-info">
        <span>Start date</span>
        <span>{dayjs(start_time).format(dateFormat)}</span>
      </div>

      <div className="net-promoter-score-card__basic-info">
        <span>End date</span>
        <span>{dayjs(end_time).format(dateFormat)}</span>
      </div>

      <div className="net-promoter-score-card__basic-info">
        <span>Participants</span>
        <span>
          {participants_completed || 0} / {participants_total}
        </span>
      </div>

      <div className="net-promoter-score-card__actions">
        <StatusElement
          small
          status={
            MAP_NET_PROMOTER_SCORE_STATUS_FOR_STATUS_ELEMENT[
              status
            ] as StatusElementStatus
          }
        >
          {MAP_NET_PROMOTER_SCORE_STATUS[status]}
        </StatusElement>
        <Button variant="ghost" icon={<Icon name="chevron-right" />} />
      </div>

      {!all && status !== 'finished' && (
        <Button
          icon={<Icon name="plus" />}
          iconPosition="left"
          variant="ghost"
          onClick={(ev) => {
            ev.stopPropagation();
            onEdit?.();
          }}
        >
          Add more communities
        </Button>
      )}
    </Styled.NetPromoterScoreCard>
  );
};

export default NetPromoterScoreCard;
