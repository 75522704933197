import { theme } from '@faxi/web-component-library';
import { flex, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const NPSSurveyChart = styled.div`
  background-color: var(--BACKGROUND_1_1);
  padding: ${theme.sizes.SIZE_16};
  border-radius: ${theme.sizes.SIZE_8};

  h2 {
    margin: 0;
    color: var(--SHADE_1_1);
    font-size: ${theme.fontSizes.FONT_24};
    font-weight: 600;
    margin-bottom: ${theme.sizes.SIZE_16};
  }

  .nps-survey-chart {
    &__overall-info {
      ${flex('row', undefined, 'center')};
    }

    &__nps-fun {
      margin: ${theme.sizes.SIZE_20};
      font-weight: 600;
      color: var(--SHADE_1_1);
    }
    &__doughnut {
      ${size(theme.sizes.SIZE_232)}
      margin-right: ${theme.sizes.SIZE_32};
      &__center-text {
        ${flex('column', undefined, 'center')};
        &__nps-score {
          font-size: ${theme.fontSizes.FONT_44};
          font-weight: 600;
        }
      }
    }

    &__votes {
      margin-right: ${theme.sizes.SIZE_72};
    }
    &__user-completed {
      ${flex('column')};
      margin-bottom: ${theme.sizes.SIZE_24};

      &__value {
        background-color: var(--PRIMARY_1_1);
        color: var(--BACKGROUND_1_1);

        width: fit-content;
        min-width: ${theme.sizes.SIZE_112};
        text-align: center;
        padding: ${theme.sizes.SIZE_4} ${theme.sizes.SIZE_16};
        font-size: ${theme.fontSizes.FONT_28};
        font-weight: 600;
        border-radius: 50px;
      }

      &__label {
        font-size: ${theme.fontSizes.FONT_24};
        color: var(--SHADE_1_1);
        line-height: ${theme.sizes.SIZE_32};
      }
    }

    &__detailed-report {
      ${flex('row')};
      gap: ${theme.sizes.SIZE_16};

      &__sub-votes {
        ${flex('column')};
        gap: ${theme.sizes.SIZE_24};
        background-color: var(--BACKGROUND_2_1);
        padding: ${theme.sizes.SIZE_10};
        border-radius: ${theme.sizes.SIZE_8};

        &__title {
          color: var(--SHADE_1_1);
          font-size: ${theme.fontSizes.FONT_20};
          font-weight: 600;
          text-align: center;
        }

        &__votes {
          ${flex('row', 'center')};
          gap: ${theme.sizes.SIZE_20};
          padding: 0 ${theme.sizes.SIZE_12};

          &__vote {
            ${flex('column', undefined, 'center')};
            gap: ${theme.sizes.SIZE_16};

            &__index {
              ${size('22px')}
              border-radius: 50%;
              font-size: ${theme.fontSizes.FONT_12};
              font-weight: 500;
              text-align: center;
              line-height: 18px;
            }
            svg {
              ${size(theme.sizes.SIZE_48)};
            }

            &__count {
              border-radius: 18px;
              padding: 4px;
              color: var(--BACKGROUND_1_1);
              min-width: ${theme.sizes.SIZE_72};
              font-size: ${theme.fontSizes.FONT_12};
              text-align: center;
              color: var(--BACKGROUND_1_1);
            }
          }
        }

        &__desc {
          font-size: ${theme.fontSizes.FONT_16};
          color: var(--SHADE_1_2);
          text-align: center;
        }
      }

      &__detractors {
        flex: 0 1 60%;

        &__vote {
          &__index {
            background-color: var(--ALERT_ERROR_1_5);
          }
          svg {
            color: var(--ALERT_ERROR_1_1);
          }

          &__count {
            background-color: var(--ALERT_ERROR_1_1);
          }
        }
      }

      &__passives {
        flex: 0 1 20%;

        .nps-survey-chart__detailed-report__sub-votes__votes__vote {
          &__index {
            background-color: var(--LABEL_TEXT_1_7);
          }
          svg {
            color: var(--ALERT_WARNING_1_1);
          }

          &__count {
            background-color: var(--ALERT_WARNING_1_1);
          }
        }
      }
      &__promoters {
        flex: 0 1 20%;

        .nps-survey-chart__detailed-report__sub-votes__votes__vote {
          &__index {
            background-color: var(--ALERT_SUCCESS_1_4);
          }
          svg {
            color: var(--ALERT_SUCCESS_DARK_1_1);
          }

          &__count {
            background-color: var(--ALERT_SUCCESS_DARK_1_1);
          }
        }
      }
    }
  }
`;
