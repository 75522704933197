import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const NetPromoterScoreCard = styled.div`
  background-color: var(--BACKGROUND_1_1);
  border-radius: ${theme.sizes.SIZE_8};
  border: 1px solid var(--SHADE_1_6);
  padding: ${theme.sizes.SIZE_16};
  cursor: pointer;

  h2 {
    font-size: ${theme.fontSizes.FONT_16};
    font-weight: 600;
    color: var(--SHADE_1_1);
    margin: 0 0 ${theme.sizes.SIZE_8} 0;
  }

  .net-promoter-score-card {
    &__basic-info {
      ${flex('row', 'space-between')};
      font-size: ${theme.fontSizes.FONT_11};
      color: var(--SHADE_1_2);
      line-height: 17px;
      margin-bottom: ${theme.sizes.SIZE_4};
    }

    &__actions {
      ${flex('row', 'space-between', 'center')};
      padding-right: ${theme.sizes.SIZE_8};
    }
  }
`;
