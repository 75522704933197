import { FC, useCallback, useMemo, useRef } from 'react';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { FieldProps } from '@faxi/web-form';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import {
  Calendar,
  Dropdown,
  Input,
  DropdownRef,
  InputProps,
  useQueryParams,
  CalendarValue,
  CalendarProps,
} from '@faxi/web-component-library';

import { dateFormat, DATE_STRING_REGEX } from 'config';
import { CALENDAR_LABELS } from './utils';
import Icon from 'components/Icon';

import * as Styled from './CalendarField.styles';

dayjs.extend(customParseFormat);

type CalendarFieldProps = {
  leftLimitDate?: Dayjs;
  rightLimitDate?: Dayjs;
  renderAsPortal?: boolean;
  onSetDate?: (date: Dayjs | null) => void;
  onChange?: (date: Dayjs | null) => void;
} & InputProps &
  Pick<
    CalendarProps,
    'disableFuture' | 'disablePast' | 'openAtToday' | 'disabledDates'
  > &
  FieldProps<string, (event: string) => void>;

const CalendarField: FC<CalendarFieldProps> = (
  props: CalendarFieldProps
): JSX.Element => {
  const {
    className,
    placeholder,
    renderAsPortal,
    leftLimitDate,
    rightLimitDate,
    value: pValue,
    disableFuture,
    disablePast,
    disabledDates,
    error,
    touched,
    dirty,
    openAtToday,
    onSetDate,
    onChange,
    ...rest
  } = props;

  const {
    params: { search },
  } = useQueryParams<{ search: string }>();

  const dropdownRef = useRef<DropdownRef>(null);
  const hasError = useMemo(() => error && touched, [error, touched]);

  const value = useMemo(
    () =>
      search && DATE_STRING_REGEX.test(search)
        ? dayjs(search).format(dateFormat)
        : pValue && pValue !== '-'
        ? dayjs(pValue, dateFormat).format(dateFormat)
        : undefined,
    [pValue, search]
  );

  const closeDropdown = useCallback(() => {
    dropdownRef?.current?.setOpen(false);
  }, []);

  const handleOnChange = useCallback(
    (date?: CalendarValue) => {
      if (!date) return;
      closeDropdown();

      onSetDate?.(date as Dayjs);
      onChange?.((date as Dayjs).format(dateFormat));
    },
    [onChange, onSetDate, closeDropdown]
  );

  return (
    <Styled.Container className={classNames('calendar-container', className)}>
      <Dropdown
        ref={dropdownRef}
        repositionContent
        hasCloudArrow={false}
        renderAsPortal={renderAsPortal}
        trigger={
          <Input
            value={value}
            readOnly={true}
            error={hasError}
            errorText={String(error)}
            placeholder={placeholder}
            prefixIcon={<Icon name="calendar-days" />}
            {...rest}
          />
        }
        body={
          <Calendar
            labels={CALENDAR_LABELS}
            disablePast={disablePast}
            disableFuture={disableFuture}
            leftLimitDate={leftLimitDate}
            rightLimitDate={rightLimitDate}
            {...(value && { value: dayjs(value, dateFormat) })}
            onClose={closeDropdown}
            onChange={handleOnChange}
            openAtToday={openAtToday}
            disabledDates={disabledDates}
          />
        }
      />
    </Styled.Container>
  );
};

export default CalendarField;
