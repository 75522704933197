import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const NetPromoteScoreSurvey = styled.div`
  background: var(--BACKGROUND_2_1);
  padding: ${theme.sizes.SIZE_24} ${theme.sizes.SIZE_48};
  overflow: auto;

  .net-promoter-score-survey {
    &__back-btn {
      margin: ${theme.sizes.SIZE_20} 0;
    }
    &__header {
      ${flex('row', 'space-between')}
      padding: ${theme.sizes.SIZE_16};
      background-color: var(--BACKGROUND_1_1);
      border-radius: ${theme.sizes.SIZE_8};

      h1 {
        color: var(--SHADE_1_2);
        font-size: ${theme.fontSizes.FONT_26};
        font-weight: 600;
        margin: 0;
      }

      &__actions {
        display: flex;
        gap: ${theme.sizes.SIZE_48};
      }

      margin-bottom: ${theme.sizes.SIZE_24};
    }

    &__additional-info {
      display: flex;
      gap: ${theme.sizes.SIZE_24};
      margin-bottom: ${theme.sizes.SIZE_24};
    }

    &__card {
      background-color: var(--BACKGROUND_1_1);
      border-radius: ${theme.sizes.SIZE_8};
      padding: ${theme.sizes.SIZE_20};
      flex: 1;

      &__header {
        ${flex('row', 'space-between', 'baseline')};

        svg {
          color: var(--PRIMARY_1_1);
        }
        h2 {
          color: var(--SHADE_1_2);
          font-size: ${theme.fontSizes.FONT_16};
          font-weight: 600;
          margin: 0;
        }

        &__heading {
          ${flex('row')};
          gap: ${theme.sizes.SIZE_12};
          margin-bottom: ${theme.sizes.SIZE_24};
        }
      }

      &__data {
        ${flex('row', 'space-between')};
        font-size: ${theme.fontSizes.FONT_14};
        color: var(--SHADE_1_2);
        margin-bottom: ${theme.sizes.SIZE_10};
        
        &--bold {
          font-weight: 600;
        }
      }
    }

    &__communities {
      max-width: ${theme.sizes.SIZE_304};
      margin-bottom: ${theme.sizes.SIZE_24};
    }

    &__mobile-app {
      margin-bottom: ${theme.sizes.SIZE_16};
    }
  }
`;
