import { SortDirections } from '@faxi/web-component-library';
import {
  APIResponse,
  CommunityEKG,
  CreateCommunityData,
  TableUser,
  User,
} from 'models';
import { CarParkSpace, CarParkType } from 'models/CarPark';
import {
  Community,
  CommunityStatus,
  PremiumFeature,
  PremiumFeaturesNames,
} from 'models';
import { PaginatedResponse } from 'models/PaginatedData';
import { CommunitySettingsType } from 'pages/Communities/components/CommunitySettingsModal/CommunitySettingsModal.component';
import { CarParkFormType } from 'pages/Communities/pages/CarPark/components/CarParkModal/CarParkModal.component';
import httpClient from '../httpClient';

type GetAllCommunitiesProps = {
  page?: number;
  search?: string;
  per_page?: number;
  sort_by?: keyof Community;
  sort_direction?: SortDirections;
  orglist?: number;
  //only if orglist is set to 1 search_key and search_value can be used
  search_key?: string;
  search_value?: string;
  hide_test?: boolean;
};

type GetAdminsProps = {
  per_page?: number;
  page?: number;
  search?: string;
  sort_by?: keyof TableUser;
  sort_direction?: SortDirections;
};

const createCommunity = async (data: CreateCommunityData) =>
  httpClient
    .post<APIResponse<CreateCommunityData>>('/organisations', data)
    .then((res) => res.data);

const updateCommunity = async (
  id: number,
  data: Partial<CreateCommunityData>
) =>
  httpClient
    .put<APIResponse<CreateCommunityData>>(`/organisations/${id}`, data)
    .then((res) => res.data);

const getAllCommunities = ({
  page,
  search,
  per_page,
  sort_by,
  sort_direction,
  orglist,
  search_key,
  search_value,
  hide_test,
}: GetAllCommunitiesProps) =>
  httpClient.get<PaginatedResponse<Community, 'organisations'>>(
    '/organisations',
    {
      params: {
        page,
        search,
        per_page,
        sort: (sort_by || sort_direction) && `${sort_by}:${sort_direction}`,
        orglist,
        search_key,
        search_value,
        hide_test,
      },
    }
  );

const getCommunity = async (id: number) =>
  httpClient
    .get<APIResponse<CreateCommunityData>>(`/organisations/${id}`)
    .then((res) => res.data);

type GetSearchCommunitiesParams = {
  page?: string;
  search?: string;
  per_page?: string;
  all?: 1;
};

const getSearchCommunities = (params?: GetSearchCommunitiesParams) =>
  httpClient.get<
    PaginatedResponse<{ id: number; name: string }, 'organisations'>
  >('/organisations/dropdown', {
    params,
  });

const getCarParks = async (
  oid: string,
  page?: number,
  per_page?: number,
  searchString?: { param: string; value: string }
) =>
  httpClient.get<PaginatedResponse<CarParkType, 'carparks'>>(
    `organisations/${oid}/carparks`,
    {
      params: {
        per_page,
        page,
        [`${searchString?.param}`]: searchString?.value,
      },
    }
  );

const getCarParkSpaces = async (
  oid: string,
  cpid: string,
  page?: number,
  per_page?: number,
  searchString?: { param: string; value: string }
) =>
  httpClient.get<PaginatedResponse<CarParkSpace, 'spaces'>>(
    `organisations/${oid}/carparks/${cpid}/spaces`,
    {
      params: {
        per_page,
        page,
        [`${searchString?.param}`]: searchString?.value,
      },
    }
  );

const createCarPark = (oid: string, data: CarParkFormType) =>
  httpClient.post<APIResponse<CarParkType>>(`organisations/${oid}/carparks`, {
    ...data,
  });

const updateCarPark = (
  oid: string,
  carparkId: number,
  data: Partial<CarParkType>
) =>
  httpClient.put<APIResponse<CarParkType>>(
    `organisations/${oid}/carparks/${carparkId}`,
    {
      ...data,
    }
  );

const deleteCarPark = (oid: string, carparkId: number) =>
  httpClient.delete<APIResponse<CarParkType>>(
    `organisations/${oid}/carparks/${carparkId}`
  );

const updateCommunityLicence = (
  oid: number,
  premium_status: string,
  pocs: string,
  end_of_premium?: string
) =>
  httpClient.put(`organisations/${oid}/licence/update`, {
    premium_status,
    pocs,
    end_of_premium,
  });

const updateCommunityJSON = (oid: number, json: string) =>
  httpClient.put<APIResponse<CommunitySettingsType>>(
    `organisations/${oid}/json/update`,
    {
      json,
    }
  );

const getCommunityJSON = (oid: string) =>
  httpClient.get<APIResponse<CommunitySettingsType>>(
    `organisations/${oid}/json`
  );

const updatePremiumFeatures = (
  oid: number,
  premium_features: Array<PremiumFeaturesNames>
) =>
  httpClient
    .post<APIResponse<{ premium_features: PremiumFeature[] }>>(
      `organisations/${oid}/premium-features`,
      {
        premium_features,
      }
    )
    .then((res) => res.data);

const getPremiumFeatures = () =>
  httpClient
    .get<APIResponse<Array<PremiumFeature>>>(`premium-features`)
    .then((res) => res.data);

const updateCommunityStatus = (oid: string, status: CommunityStatus) =>
  httpClient.put(`organisations/${oid}/status`, {
    status,
  });

const getEKG = (page: string, search?: string, per_page?: string) =>
  httpClient.get<PaginatedResponse<CommunityEKG>>('organisations/list/all', {
    params: { page, search, per_page },
  });

const getCommunityAdmins = (
  oid: string,
  { per_page, page, search, sort_by, sort_direction }: GetAdminsProps
) =>
  httpClient.get<PaginatedResponse<User, 'users'>>(
    `organisations/${oid}/admins`,
    {
      params: {
        per_page,
        page,
        search,
        sort: `${sort_by}:${sort_direction}`,
      },
    }
  );

const checkCommunityNameUnique = (name: string) => {
  const formData = new FormData();
  formData.append('name', name);

  return httpClient.post('/organisations/validate-name', formData);
};

export default {
  createCommunity,
  updateCommunity,
  getAllCommunities,
  getCommunity,
  getSearchCommunities,
  updateCommunityLicence,
  updateCommunityJSON,
  getCommunityJSON,
  getCarParks,
  getCarParkSpaces,
  createCarPark,
  updateCarPark,
  deleteCarPark,
  updatePremiumFeatures,
  updateCommunityStatus,
  getPremiumFeatures,
  getEKG,
  getCommunityAdmins,
  checkCommunityNameUnique,
};
