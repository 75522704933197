import { TabOption } from '@faxi/web-component-library';
import { appUri } from 'config';

export const COMMUNITIES_SEARCH_PER_PAGE = 50;

export const netPromoterScorePageBreadcrumbs = [
  { text: 'Communities', href: appUri.COMMUNITIES },
  { text: 'Net Promoter Score', href: '' },
];

export type NetPromoteScoreScreen = 'Q' | 'R';

export const NET_PROMOTE_SCORE_TABS = [
  { label: 'Questions', value: 'Q' },
  { label: 'Responses', value: 'R' },
] as Array<TabOption<NetPromoteScoreScreen>>;

export const MAP_NET_PROMOTER_SCORE_TYPE = {
  all: 'All communities',
  selected: 'Selected communities',
};
export const MAP_NET_PROMOTER_SCORE_STATUS = {
  active: 'Ongoing',
  finished: 'Finished',
  pending: 'Pending',
  canceled: 'Canceled',
};
export const MAP_NET_PROMOTER_SCORE_STATUS_FOR_STATUS_ELEMENT = {
  active: 'active',
  finished: 'canceled',
  pending: 'pending',
  canceled: 'rejected',
};

export const forCommunitiesRadioOptions = [
  { value: 'all', label: 'All communities' },
  {
    value: 'per_community',
    label: 'Per community',
  },
];

export const runSurveyFormInitialData = {
  forCommunities: 'all',
};
