import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import {
  Breadcrumbs,
  Button,
  Select,
  SelectOption,
  StatusElement,
  StatusElementStatus,
  useUtilities,
} from '@faxi/web-component-library';

import { appUri, dateFormat } from 'config';

import { NetPromoterScoreSurveyType } from 'models';
import { Icon } from 'components';
import { useHistory, useParams } from 'react-router';
import dayjs from 'dayjs';
import { apiCommunities, apiNetPromoterScore } from 'modules';
import NPSSurveyChart from './components/NPSSurveyChart';
import {
  MAP_NET_PROMOTER_SCORE_STATUS_FOR_STATUS_ELEMENT,
  MAP_NET_PROMOTER_SCORE_TYPE,
  MAP_NET_PROMOTER_SCORE_STATUS,
} from 'pages/NetPromoterScore/constants';

import * as Styled from './NetPromoterScoreSurvey.styles';

const NetPromoterScoreSurvey = () => {
  const [netPromoterScoreSurvey, setNetPromoterScoreSurvey] =
    useState<NetPromoterScoreSurveyType>();

  const [options, setOptions] = useState<SelectOption<string>[]>([]);
  const [selectedCommunity, setSelectedCommunity] = useState<SelectOption>();

  const history = useHistory();

  const { showOverlay, hideOverlay } = useUtilities();

  const { netPromoterScoreId } = useParams<{
    netPromoterScoreId: string;
  }>();

  const breadcrumbs = useMemo(() => {
    return [
      { text: 'Communities', href: appUri.COMMUNITIES },
      { text: 'Net Promoter Score', href: appUri.NET_PROMOTER_SCORE },
      {
        text: MAP_NET_PROMOTER_SCORE_TYPE[
          netPromoterScoreSurvey?.all ? 'all' : 'selected'
        ],
        href: '',
      },
    ];
  }, [netPromoterScoreSurvey?.all]);

  const getAllCommunities = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await apiCommunities.getSearchCommunities({ all: 1 });

      setOptions(
        data.organisations.map((organisation) => ({
          id: `${organisation.id}`,
          label: organisation.name,
          value: `${organisation.id}`,
        }))
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  /*
  only communities which was alredy included in survey
  **/
  const filteredOptions = useMemo(() => {
    return options.filter(
      (option) =>
        option.id && netPromoterScoreSurvey?.organisations?.includes(+option.id)
    );
  }, [netPromoterScoreSurvey?.organisations, options]);

  useEffect(() => {
    getAllCommunities();
  }, [getAllCommunities]);

  const getNetPromoterScoreSurvey = useCallback(async () => {
    try {
      showOverlay('.net-promoter-score-survey__mobile-app');
      showOverlay('.net-promoter-score-survey__admin-app');
      const {
        data: { data },
      } = await apiNetPromoterScore.getPromoterScoreSurveyById(
        netPromoterScoreId,
        selectedCommunity?.id
      );
      if (data) {
        setNetPromoterScoreSurvey(data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      hideOverlay('.net-promoter-score-survey__mobile-app');
      hideOverlay('.net-promoter-score-survey__admin-app');
    }
  }, [hideOverlay, netPromoterScoreId, selectedCommunity?.id, showOverlay]);

  useEffect(() => {
    getNetPromoterScoreSurvey();
  }, [getNetPromoterScoreSurvey]);

  const handleEndSurvey = useCallback(async () => {
    try {
      const { data } = await apiNetPromoterScore.stopNetPromoterScoreSurvey(
        +netPromoterScoreId
      );

      if (data) {
        getNetPromoterScoreSurvey();
      }
    } catch (e) {
      console.error(e);
    }
  }, [getNetPromoterScoreSurvey, netPromoterScoreId]);

  return (
    <Styled.NetPromoteScoreSurvey className="net-promoter-score-survey">
      <Breadcrumbs crumbs={breadcrumbs} />
      <Button
        variant="ghost"
        icon={<Icon name="chevron-left" />}
        onClick={() => {
          history.push(appUri.NET_PROMOTER_SCORE);
        }}
        className="net-promoter-score-survey__back-btn"
      >
        Back
      </Button>

      <div className="net-promoter-score-survey__header">
        <h1>
          {
            MAP_NET_PROMOTER_SCORE_TYPE[
              netPromoterScoreSurvey?.all ? 'all' : 'selected'
            ]
          }
        </h1>

        <div className="net-promoter-score-survey__header__actions">
          {netPromoterScoreSurvey &&
            netPromoterScoreSurvey?.status !== 'finished' && (
              <Button
                icon={<Icon name="circle-stop" />}
                variant="delete-ghost"
                onClick={handleEndSurvey}
              >
                End Survey
              </Button>
            )}

          {/* <Button icon={<Icon name="file-pdf" />}>View PDF</Button> */}
        </div>
      </div>

      <div className="net-promoter-score-survey__additional-info">
        <div
          className={classNames([
            'net-promoter-score-survey__card',
            'net-promoter-score-survey__status-info',
          ])}
        >
          <div className="net-promoter-score-survey__card__header">
            <div className="net-promoter-score-survey__card__header__heading">
              <Icon name="clock" />
              <h2>STATUS</h2>
            </div>
            {netPromoterScoreSurvey?.status && (
              <StatusElement
                small
                status={
                  MAP_NET_PROMOTER_SCORE_STATUS_FOR_STATUS_ELEMENT[
                    netPromoterScoreSurvey?.status
                  ] as StatusElementStatus
                }
              >
                {MAP_NET_PROMOTER_SCORE_STATUS[netPromoterScoreSurvey.status]}
              </StatusElement>
            )}
          </div>
          <div className="net-promoter-score-survey__card__data">
            <span>Start Date</span>
            <span>
              {dayjs(netPromoterScoreSurvey?.start_time).format(dateFormat)}
            </span>
          </div>

          <div className="net-promoter-score-survey__card__data">
            <span>End Date</span>
            <span>
              {dayjs(netPromoterScoreSurvey?.end_time).format(dateFormat)}
            </span>
          </div>
        </div>

        <div
          className={classNames([
            'net-promoter-score-survey__card',
            'net-promoter-score-survey__audience-info',
          ])}
        >
          <div className="net-promoter-score-survey__card__header">
            <div className="net-promoter-score-survey__card__header__heading">
              <Icon name="users" />
              <h2>AUDIENCE</h2>
            </div>
          </div>
          <div className="net-promoter-score-survey__card__data">
            <span>Total Participants</span>
            <span>{netPromoterScoreSurvey?.participants_total}</span>
          </div>

          <div className="net-promoter-score-survey__card__data">
            <span>Participants completed survey</span>
            <span className="net-promoter-score-survey__card__data--bold">
              {netPromoterScoreSurvey?.participants_completed}
            </span>
          </div>
        </div>
      </div>

      <Select
        autoComplete="off"
        options={filteredOptions}
        placeholder={'All communities'}
        searchable
        renderAsPortal
        className="net-promoter-score-survey__communities"
        value={selectedCommunity?.value}
        onChange={(value) => setSelectedCommunity(value)}
        onClear={() => setSelectedCommunity(undefined)}
        hasClearAction
        clearTitle="Clear selection"
      />

      {netPromoterScoreSurvey?.regular_user && (
        <NPSSurveyChart
          type="mobile"
          completedCount={netPromoterScoreSurvey?.regular_user.total}
          votes={Object.values(
            netPromoterScoreSurvey?.regular_user.answers_count
          )}
          className="net-promoter-score-survey__mobile-app"
        />
      )}
      {netPromoterScoreSurvey?.client_admin && (
        <NPSSurveyChart
          type="admin"
          completedCount={netPromoterScoreSurvey?.client_admin.total}
          votes={Object.values(
            netPromoterScoreSurvey?.client_admin.answers_count
          )}
          className="net-promoter-score-survey__admin-app"
        />
      )}
    </Styled.NetPromoteScoreSurvey>
  );
};

export default NetPromoterScoreSurvey;
