import { getColor } from '@faxi/web-component-library';
import classNames from 'classnames';
import { Icon } from 'components';
import { useMemo } from 'react';
import DoughnutChart from '../DoughnutChart';
import VotesCount from './components/VotesCount';

import * as Styled from './NPSSurveyChart.styles';

const MAP_NPS_SURVEY_CHART_TYPE = {
  mobile: 'Mobile app',
  admin: 'Admin app',
};

type NPSSurveyChartProps = {
  type: 'mobile' | 'admin';
  completedCount: number;
  votes: number[];
  className?: string;
};

const NPSSurveyChart = (props: NPSSurveyChartProps) => {
  const { type, completedCount, votes, className } = props;
  const classes = classNames(className);

  const votesCounts: {
    detractors: number;
    passives: number;
    promoters: number;
  } = useMemo(
    () =>
      votes.reduce(
        (acc, num, index) => {
          if (index < 7) acc.detractors += num;
          else if (index < 9) acc.passives += num;
          else acc.promoters += num;
          return acc;
        },
        { detractors: 0, passives: 0, promoters: 0 }
      ),
    [votes]
  );

  const NPSScore = useMemo(() => {
    return (
      (100 * votesCounts.promoters) / completedCount -
        (100 * votesCounts.detractors) / completedCount || 0
    ).toFixed(0);
  }, [completedCount, votesCounts.detractors, votesCounts.promoters]);

  const chartData = useMemo(() => {
    return [
      {
        value: votesCounts.promoters,
        color: getColor('--ALERT_SUCCESS_1_1'),
      },
      {
        value: votesCounts.passives,
        color: getColor('--ALERT_WARNING_1_1'),
      },
      {
        value: votesCounts.detractors,
        color: getColor('--ALERT_ERROR_1_1'),
      },
    ].filter((el) => el.value);
  }, [votesCounts.detractors, votesCounts.passives, votesCounts.promoters]);

  return (
    <Styled.NPSSurveyChart className={classes}>
      <h2>{MAP_NPS_SURVEY_CHART_TYPE[type]}</h2>
      <div className="nps-survey-chart__overall-info">
        <DoughnutChart
          className="nps-survey-chart__doughnut"
          centerText={
            <div className="nps-survey-chart__doughnut__center-text">
              NPS Score
              <span className="nps-survey-chart__doughnut__center-text__nps-score">
                {NPSScore}
              </span>
            </div>
          }
          chartData={chartData}
        />
        <div className="nps-survey-chart__votes">
          {Object.entries(votesCounts).map(([key, value], ind) => (
            <VotesCount
              key={ind}
              type={key as any}
              count={value}
              percentage={(100 * value) / completedCount || 0}
            />
          ))}
        </div>
        <div className="nps-survey-chart__user-completed">
          <span className="nps-survey-chart__user-completed__value">
            {completedCount}
          </span>
          <span className="nps-survey-chart__user-completed__label">
            user completed survey
          </span>
        </div>
      </div>

      <div className="nps-survey-chart__nps-fun">
        NPS = %Promoters - %Detractors
      </div>

      <div className="nps-survey-chart__detailed-report">
        {/* DETRACTORS */}
        <div
          className={classNames([
            'nps-survey-chart__detailed-report__sub-votes',
            'nps-survey-chart__detailed-report__detractors',
          ])}
        >
          <span className="nps-survey-chart__detailed-report__sub-votes__title">
            Needs Improvement
          </span>

          <div
            className={classNames([
              'nps-survey-chart__detailed-report__sub-votes__votes',
              'nps-survey-chart__detailed-report__detractors__votes',
            ])}
          >
            {votes.slice(0, 7).map((vote, ind) => (
              <div
                key={ind}
                className={classNames([
                  'nps-survey-chart__detailed-report__sub-votes__votes__vote',
                  'nps-survey-chart__detailed-report__detractors__vote',
                ])}
              >
                <span
                  className={classNames([
                    'nps-survey-chart__detailed-report__sub-votes__votes__vote__index',
                    'nps-survey-chart__detailed-report__detractors__vote__index',
                  ])}
                >
                  {ind}
                </span>
                <Icon name="face-frown-slight" />
                <span
                  className={classNames([
                    'nps-survey-chart__detailed-report__detractors__vote__count',
                    'nps-survey-chart__detailed-report__sub-votes__votes__vote__count',
                  ])}
                >
                  {vote} users
                </span>
              </div>
            ))}
          </div>

          <span className="nps-survey-chart__detailed-report__sub-votes__desc">
            Detractors
          </span>
        </div>

        {/* PASSIVES */}
        <div
          className={classNames([
            'nps-survey-chart__detailed-report__sub-votes',
            'nps-survey-chart__detailed-report__passives',
          ])}
        >
          <span className="nps-survey-chart__detailed-report__sub-votes__title">
            Good
          </span>

          <div
            className={classNames([
              'nps-survey-chart__detailed-report__sub-votes__votes',
              // 'nps-survey-chart__detailed-report__passives__votes',
            ])}
          >
            {votes.slice(7, 9).map((vote, ind) => (
              <div
                key={ind}
                className={classNames([
                  'nps-survey-chart__detailed-report__sub-votes__votes__vote',
                  // 'nps-survey-chart__detailed-report__passives__vote',
                ])}
              >
                <span className="nps-survey-chart__detailed-report__sub-votes__votes__vote__index">
                  {ind + 7}
                </span>
                <Icon name="face-meh" />
                <span className="nps-survey-chart__detailed-report__sub-votes__votes__vote__count">
                  {vote} users
                </span>
              </div>
            ))}
          </div>

          <span className="nps-survey-chart__detailed-report__sub-votes__desc">
            Passives
          </span>
        </div>

        {/* PROMOTERS */}
        <div
          className={classNames([
            'nps-survey-chart__detailed-report__sub-votes',
            'nps-survey-chart__detailed-report__promoters',
          ])}
        >
          <span className="nps-survey-chart__detailed-report__sub-votes__title">
            Excellent
          </span>

          <div
            className={classNames([
              'nps-survey-chart__detailed-report__sub-votes__votes',
              'nps-survey-chart__detailed-report__passives__votes',
            ])}
          >
            {votes.slice(9, 11).map((vote, ind) => (
              <div
                key={ind}
                className={classNames([
                  'nps-survey-chart__detailed-report__sub-votes__votes__vote',
                  'nps-survey-chart__detailed-report__passives__vote',
                ])}
              >
                <span className="nps-survey-chart__detailed-report__sub-votes__votes__vote__index">
                  {ind + 9}
                </span>
                <Icon name="face-meh" />
                <span className="nps-survey-chart__detailed-report__sub-votes__votes__vote__count">
                  {vote} users
                </span>
              </div>
            ))}
          </div>

          <span className="nps-survey-chart__detailed-report__sub-votes__desc">
            Promoters
          </span>
        </div>
      </div>
    </Styled.NPSSurveyChart>
  );
};

export default NPSSurveyChart;
