import {
  Breadcrumbs,
  Button,
  Heading,
  Tabs,
  useUtilities,
} from '@faxi/web-component-library';
import { Icon } from 'components';
import dayjs, { Dayjs } from 'dayjs';
import { NetPromoterScoreBasic } from 'models';
import { apiNetPromoterScore } from 'modules';
import { useCallback, useEffect, useMemo, useState } from 'react';
import AddMoreCommunitiesModal from './components/AddMoreCommunitiesModal';
import NetPromoterScoreCard from './components/NetPromoterScoreCard';
import RunNewSurveyModal from './components/RunNewSurveyModal';
import {
  netPromoterScorePageBreadcrumbs,
  NetPromoteScoreScreen,
  NET_PROMOTE_SCORE_TABS,
} from './constants';
import { getDatesBetween } from './utils';

import * as Styled from './NetPromoterScore.styles';

const NetPromoteScore = () => {
  const [netPromoteScoreScreen, setNetPromoteScoreScreen] =
    useState<NetPromoteScoreScreen>('Q');
  const [netPromoterScores, setNetPromoterScores] = useState<
    NetPromoterScoreBasic[]
  >([]);
  const [loadingNetPromoterScores, setLoadingNetPromoterScores] =
    useState(false);
  const [runSurveyModal, setRunSurveyModal] = useState(false);
  const [addMoreCommunitiesModal, setAddMoreCommunitiesModal] =
    useState<NetPromoterScoreBasic>();

  const { showOverlay, hideOverlay } = useUtilities();

  const getNetPromoterScores = useCallback(async () => {
    setLoadingNetPromoterScores(true);
    showOverlay('body');
    try {
      const {
        data: { data },
      } = await apiNetPromoterScore.getAllNetPromoterScoreSurveys();

      if (data) {
        setNetPromoterScores(data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingNetPromoterScores(false);
      hideOverlay('body');
    }
  }, [hideOverlay, showOverlay]);

  useEffect(() => {
    getNetPromoterScores();
  }, [getNetPromoterScores]);

  const disabledDates = useMemo(
    () =>
      netPromoterScores?.reduce(
        (dates, s) =>
          s.status !== 'canceled'
            ? [
                ...dates,
                ...getDatesBetween(dayjs(s.start_time), dayjs(s.end_time)),
              ]
            : dates,
        [] as Dayjs[]
      ),
    [netPromoterScores]
  );

  return (
    <Styled.NetPromoteScore>
      <Breadcrumbs crumbs={netPromoterScorePageBreadcrumbs} />
      <Heading level="1">Net Promoter Score</Heading>
      <p className="net-promote-score__desc">
        Mobile app users and admins on the admin app will get a one question
        survey
      </p>
      <Button
        className="net-promote-score__run-survey-btn"
        onClick={() => {
          setRunSurveyModal(true);
        }}
      >
        Run survey
      </Button>
      <Tabs<NetPromoteScoreScreen>
        className="net-promote-score__tabs"
        value={netPromoteScoreScreen}
        tabs={NET_PROMOTE_SCORE_TABS}
        onChange={setNetPromoteScoreScreen}
      />
      {netPromoteScoreScreen === 'Q' ? (
        <div className="net-promote-score__questions">
          <div className="net-promote-score__info-lbl">Question 1</div>
          <p className="net-promote-score__info">
            On a scale of 0 to 10, how likely are you to recommend our app to a
            friend or colleague?
          </p>

          <div>
            <p className="net-promote-score__info-lbl">Possible answers:</p>
            <ul className="net-promote-score__info">
              {'0 (Very Unlikely), 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 (Very Likely)'
                .split(', ')
                .map((answer, index) => (
                  <li key={index}>{answer.trim()}</li>
                ))}
            </ul>
          </div>
        </div>
      ) : (
        <div className="net-promote-score__responses">
          {!loadingNetPromoterScores &&
            (netPromoterScores?.length === 0 ? (
              <div className="net-promote-score__responses__empty-state">
                <Icon name="square-poll-vertical"></Icon>
                <Heading level="2">No surveys yet</Heading>
              </div>
            ) : (
              <div className="net-promote-score__responses__list">
                {netPromoterScores?.map((netPromoterScore) => (
                  <NetPromoterScoreCard
                    key={netPromoterScore.id}
                    netPromoterScore={netPromoterScore}
                    onEdit={() => {
                      setAddMoreCommunitiesModal(netPromoterScore);
                    }}
                  />
                ))}
              </div>
            ))}
        </div>
      )}

      {runSurveyModal && (
        <RunNewSurveyModal
          onRunSurvey={() => {
            getNetPromoterScores();
            setNetPromoteScoreScreen('R');
          }}
          onClose={() => setRunSurveyModal(false)}
          disabledDates={disabledDates}
        />
      )}

      {addMoreCommunitiesModal && (
        <AddMoreCommunitiesModal
          onClose={() => {
            setAddMoreCommunitiesModal(undefined);
          }}
          netPromoterScore={addMoreCommunitiesModal}
          onAddMoreCommunities={() => {
            getNetPromoterScores();
          }}
        />
      )}
    </Styled.NetPromoteScore>
  );
};

export default NetPromoteScore;
